<template>
  <div class="ver visitas">
<siteVisits/>
  </div>
</template>

<script>
// @ is an alias to /src
import siteVisits from '@/components/siteVisits.vue'

export default {
  name: 'visitas',
  components: {
    siteVisits
   
   
    
  }
}
</script>