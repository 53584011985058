<template>
  <div class="crear clientes">
<createCustomer/>
  </div>
</template>

<script>
// @ is an alias to /src
import createCustomer from '@/components/createCustomer.vue'

export default {
  name: 'clientes',
  components: {
    createCustomer
   
   
    
  }
}
</script>