<template>
  <div class="ver empleados">
<createEmployee/>
  </div>
</template>

<script>
// @ is an alias to /src
import createEmployee from '@/components/createEmployee.vue'

export default {
  name: 'empleados',
  components: {
    createEmployee
   
   
    
  }
}
</script>