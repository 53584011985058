<template>
  <div class="container">
    <div>
      <h2>Registrar Llegada a sitio</h2>

      <div class="card">
        <div class="card-body">
          <form v-on:submit.prevent="saveNewCustomer()">
            <select
              class="form-control input-header"
              v-model="Clientes.idemployee"
            >
              <option selected disabled value="">
                SELECCIONE INGENIERO DE SOPORTE
              </option>
              <option value="1">ANDRES GARZON</option>
              <option value="2">CARLOS CASAS</option>
              <option value="3">FERNANDO MOLANO</option>
              <option value="4">JHOAN LOPEZ</option>
              <option value="5">LEONARDO NEIRA</option>
              <option value="6">ALVARO RAMIREZ</option>
              <option value="7">ESTIVEN RINCON</option></select
            ><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Razon social"
              v-model="Clientes.razon_social"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Persona que recibe visita"
              v-model="Clientes.person_receives_visit"
            /><br />

            <button type="submit" class="btn btn-primary btn-block">
              Registrar Visita
            </button>
          </form>
        </div>
      </div>
      <br />
      <br />
      <h2>Registrar Salida del Punto</h2>
      <div class="card">
        <div class="card-body">
          <form v-on:submit.prevent="savetechnicalVisits()">
            <select
              class="form-control input-header"
              v-model="technicalVisits.idemployee"
            >
              <option selected disabled value="">
                SELECCIONE INGENIERO DE SOPORTE
              </option>
              <option value="1">ANDRES GARZON</option>
              <option value="2">CARLOS CASAS</option>
              <option value="3">FERNANDO MOLANO</option>
              <option value="4">JHOAN LOPEZ</option>
              <option value="5">LEONARDO NEIRA</option>
              <option value="6">ALVARO RAMIREZ</option>
              <option value="7">ESTIVEN RINCON</option></select
            ><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Razon social"
              v-model="technicalVisits.razon_social"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Persona que recibe visita"
              v-model="technicalVisits.person_receives_visit"
            /><br />
            <textarea
              class="form-control text-uppercase"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="TEMAS VISTOS Y OBSERVACIONES"
              v-model="technicalVisits.issuesSolved"
            ></textarea
            ><br />
            <select
              class="form-control input-header"
              v-model="technicalVisits.typeOfVisit"
            >
              <option selected disabled value="">TIPO DE VISITA</option>
              <option value="PRIMERA CAPACITACION">PRIMERA CAPACITACION</option>
              <option value="SEGUNDA CAPACITACION">SEGUNDA CAPACITACION</option>
              <option value="TERCERA CAPACITACION">TERCERA CAPACITACION</option>
              <option value="CAPACITACION ADICIONAL">
                CAPACITACION ADICIONAL
              </option>
              <option value="SERVICIO TECNICO">SERVICIO TECNICO</option>
            </select>
            <br />
            <select
              class="form-control input-header"
              v-model="technicalVisits.satisfied"
            >
              <option selected disabled value="">
                CLIENTE CONFORME CON VISITA
              </option>
              <option value="SI">SI</option>
              <option value="NO">NO</option>
            </select>
            <br />
            <button type="submit" class="btn btn-primary btn-block">
              Guardar Salida
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Clientes: {
        idemployee: "",
        razon_social: "",
        person_receives_visit: "",
      },

      technicalVisits: {
        idemployee: "",
        razon_social: "",
        person_receives_visit: "",
        issuesSolved: "",
        typeOfVisit: "",
        satisfied: "",
      },
    };
  },
  methods: {
    saveNewCustomer() {
      const this_ = this;
      let razon_social = this.Clientes.razon_social;
      let person_receives_visit = this.Clientes.person_receives_visit;
      let idemployee = this.Clientes.idemployee;
      const ClientesMaysc = {
        razon_social: razon_social.toUpperCase(),
        person_receives_visit: person_receives_visit.toUpperCase(),
        idemployee,
      };

      if (
        ClientesMaysc.person_receives_visit === "" ||
        ClientesMaysc.razon_social === ""
      ) {
        this.AlertaDatosFaltantes();
        return false;
      } else {
        axios
          .post(this.$conexion + "/customers", ClientesMaysc)
          .then((response) => {
            console.log(response);
            this.AlertaRegistro();
            //    this.ClientesMaysc = "";
            this_.Clientes = {
              person_receives_visit: "",
              razon_social: "",
            };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    savetechnicalVisits() {
      const this_ = this;
      let idemployee = this.technicalVisits.idemployee;
      let razon_social = this.technicalVisits.razon_social;
      let person_receives_visit = this.technicalVisits.person_receives_visit;
      let issuesSolved = this.technicalVisits.issuesSolved;
      let typeOfVisit = this.technicalVisits.typeOfVisit;
      let satisfied = this.technicalVisits.satisfied;
      const technicalVisit = {
        idemployee,
        razon_social: razon_social.toUpperCase(),
        person_receives_visit: person_receives_visit.toUpperCase(),
        issuesSolved: issuesSolved.toUpperCase(),
        typeOfVisit,
        satisfied,
      };
      if (
        technicalVisit.person_receives_visit === "" ||
        technicalVisit.razon_social === "" ||
        technicalVisit.issuesSolved === "" ||
        technicalVisit.typeOfVisit === "" ||
        technicalVisit.satisfied === "" || technicalVisit.idemployee=== ""
      ) {
        this.AlertaDatosFaltantes();
        return false;
      } else {
        axios
          .post(this.$conexion + "/customers/visit", technicalVisit)
          .then((response) => {
            console.log(response);
            this.AlertaSalida();
           
            this_.technicalVisits = {
              person_receives_visit: "",
              razon_social: "",
              issuesSolved: "",
              typeOfVisit: "",
              satisfied: "",
              idemployee: "",
            };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    AlertaRegistro() {
      this.$swal(
        "Confirmación!",
        `Visita al cliente ${this.Clientes.razon_social} creada satisfactoriamente`,
        "success"
      );
    },
     AlertaSalida() {
      this.$swal(
        "Confirmación!",
        `Registro de salida con el cliente ${this.technicalVisits.razon_social} se ha generado`,
        "success"
      );
    },
    AlertaDatosFaltantes() {
      this.$swal("Alerta!", `Debe diligenciar todo el fomulario`, "warning");
    },
  },
};
</script>