import Vue from 'vue'
import VueRouter from 'vue-router'
import getEmployees from '../views/get_employees.vue'
import createEmployee from '../views/create_employee.vue'
import createCustomer from '../views/create_customer.vue'
import siteVisits from '../views/site_visits.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Ingreso App',
    component: getEmployees
  },
  {
    path: '/create-employee',
    name: 'Crear Empleado',
    component: createEmployee
  },
  {
    path: '/create-customer',
    name: 'Crear Cliente',
    component: createCustomer
  },
  {
    path: '/site-visits',
    name: 'ver visitas',
    component: siteVisits
  },
]

const router = new VueRouter({
  routes
})

export default router
