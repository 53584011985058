<template>
  <div class="container">
    <div>
      <h2>INGRESO DE EMPLEADOS</h2>
      <table class="table table-bordered" id="datatable">
        <thead>
          <tr>
            <th>Primer Nombre</th>
            <th>Primer Apellido</th>
            <th>Cedula</th>
            <th>Cargo</th>
            <th>Fecha y hora ingreso</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in datos" :key="item.item">
            <td>{{ item.firtsname }}</td>
            <td>{{ item.lastname }}</td>
            <td>{{ item.cedula }}</td>
            <td>{{ item.cargo }}</td>
            <td>
              {{
                new Date(item.created_at).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="card">
        <div class="card-body">
          <form v-on:submit.prevent="getEmployeeByDni()">
            <h5 class="display-5 text-dark">Buscar por Cedula:</h5>
            <input
              type="text"
              class="form-control form-control-sm"
              id="barras"
              placeholder="Cedula"
              v-model="cedula"
              autofocus
            /><br />
          </form>
          <form v-on:submit.prevent="saveEmployee()">
            <h5 class="display-5 text-dark">Registrar Ingreso a Syscomp</h5>

            <input
              type="text"
              class="form-control form-control-sm"
              id="barras"
              placeholder="Nombre"
              v-model="Empleados.firtsname"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm"
              id="barras"
              placeholder="Apellido"
              v-model="Empleados.lastname"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm"
              id="barras"
              placeholder="Cargo"
              v-model="Empleados.cargo"
            /><br />
            <button type="submit" class="btn btn-primary btn-block">
              Registrar Ingreso
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

 
<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import $ from "jquery";

export default {
  mounted() {
    this.obtenerEmpleados();
  },
  data() {
    return {
      datos: [],
      cedula: "",
      empleado: [],
      Empleados: {
        cargo: "",
        cedula: "",
        firtsname: "",
        lastname: "",
      },
    };
  },
  methods: {
    obtenerEmpleados() {
      axios
        .get(this.$conexion + "/")
        .then((response) => {
          this.datos = response.data;
          this.$nextTick(() => {
            $("#datatable").DataTable({
              lengthMenu: [
                [5, 10, 25, 50, -1],
                [5, 10, 25, 50, "All"],
              ],
              responsive: true,
              language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
              },
            });
          });
        })
        .catch((e) => console.log(e));
    },
    getEmployeeByDni() {
      let data = {
        cedula: this.cedula,
      };
      axios
        .post(this.$conexion + "/", data)
        .then((response) => {
          this.empleado = response.data.data[0];

          this.Empleados.firtsname = this.empleado.firtsname.toUpperCase();
          this.Empleados.lastname = this.empleado.lastname.toUpperCase();
          this.Empleados.cargo = this.empleado.cargo.toUpperCase();
          this.Empleados.cedula = this.empleado.cedula.toUpperCase();
          this.cedula = "";
        })
        .catch((err) => {
          console.log(err);

          this.cedula = "";
        });
    },
    saveEmployee() {
      if (
        this.Empleados.cargo === "" ||
        this.Empleados.lastname === "" ||
        this.Empleados.firtsname === ""
      ) {
        this.AlertaDatosFaltantes();
        return false;
      } else {
        axios
          .post(this.$conexion + "/create/employee", this.Empleados)
          .then((response) => {
            console.log(response);
            this.AlertaRegistro();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    AlertaRegistro() {
      this.$swal(
        "Confirmación!",
        `Empleado ${this.Empleados.firtsname} ${this.Empleados.lastname} registrado satisfactoriamente`,
        "success"
      );
    },
    AlertaDatosFaltantes() {
      this.$swal("Alerta!", `Debe diligenciar todo el fomulario`, "warning");
    },
  },
};
</script>