<template>
  <div class="ver empleados">
<getEmployees/>
  </div>
</template>

<script>
// @ is an alias to /src
import getEmployees from '@/components/getEmployees.vue'

export default {
  name: 'empleados',
  components: {
    getEmployees
   
   
    
  }
}
</script>