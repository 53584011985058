<template>
  <div class="container">
    <div>
      <h2>INGRESOS A SITIOS</h2>
      <table class="table table-bordered" id="datatable1">
        <thead>
          <tr>
            <th>Primer Nombre</th>
            <th>Primer Apellido</th>
            <th>Razon Social</th>
            <th>Encargado</th>
            <th>Fecha y hora ingreso</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in datos" :key="item.item">
            <td>{{ item.firtsname }}</td>
            <td>{{ item.lastname }}</td>
            <td>{{ item.razon_social }}</td>
            <td>{{ item.person_receives_visit }}</td>
            <td>
              {{
                new Date(item.created_at).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div>
      <h2>DATOS DE VISITAS</h2>
      <table class="table table-bordered" id="datatable2">
        <thead>
          <tr>
            <th>Primer Nombre</th>
            <th>Primer Apellido</th>
            <th>Razon Social</th>
            <th>Encargado</th>
            <th>Temas vistos</th>
            <th>Cliente satisfecho con la visita</th>
            <th>Tipo de visita</th>
            <th>Fecha y hora salida</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in visitas" :key="item.item">
            <td>{{ item.firtsname }}</td>
            <td>{{ item.lastname }}</td>
            <td>{{ item.razon_social }}</td>
            <td>{{ item.person_receives_visit }}</td>
            <td>{{ item.issuesSolved }}</td>
            <td>{{ item.satisfied }}</td>
             <td>{{ item.typeOfVisit }}</td>
            <td>
              {{
                new Date(item.createt_at).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div>
</template>

 
<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import $ from "jquery";

export default {
  mounted() {
    this.obtenerRegistrosVisitas();
    this.obtenerDatosdeVisitas();
  },
  data() {
    return {
      datos: [],
      visitas: [],
    };
  },
  methods: {
    obtenerRegistrosVisitas() {
      axios
        .get(this.$conexion + "/customers/info")
        .then((response) => {
          this.datos = response.data;
          this.$nextTick(() => {
            $("#datatable1").DataTable({
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              responsive: true,
              language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
              },
            });
          });
        })
        .catch((e) => console.log(e));
    },
    obtenerDatosdeVisitas() {
      axios
        .get(this.$conexion + "/customers/visit")
        .then((response) => {
          this.visitas = response.data;
          this.$nextTick(() => {
            $("#datatable2").DataTable({
              lengthMenu: [
                [3, 10, 25, 50, -1],
                [3, 10, 25, 50, "All"],
              ],
              responsive: true,
              language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
              },
            });
          });
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>