<template>
  <div class="container">
    <div>
      <h2>Crear Empleados</h2>

      <div class="card">
        <div class="card-body">
          <form v-on:submit.prevent="saveNewEmployee()">
            <input
              type="number"
              class="form-control form-control-sm text-uppercase"
              placeholder="Cedula"
              v-model="Empleados.cedula"
              autofocus
            /><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Nombre"
              v-model="Empleados.firtsname"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Apellido"
              v-model="Empleados.lastname"
            /><br />
            <input
              type="text"
              class="form-control form-control-sm text-uppercase"
              placeholder="Cargo"
              v-model="Empleados.cargo"
            /><br />
            <button type="submit" class="btn btn-primary btn-block">
              Registrar Empleado
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
export default {
  data() {
    return {
      Empleados: {
        cargo: "",
        cedula: "",
        firtsname: "",
        lastname: "",
      },
    };
  },
  methods: {
    saveNewEmployee() {
      const this_ = this;
      let cedula = this.Empleados.cedula;
      let firtsname = this.Empleados.firtsname;
      let lastname = this.Empleados.lastname;
      let cargo = this.Empleados.cargo;
      const EmpleadosMaysc = {
        cedula: cedula.toUpperCase(),
        firtsname: firtsname.toUpperCase(),
        lastname: lastname.toUpperCase(),
        cargo: cargo.toUpperCase(),
      };

      if (
        EmpleadosMaysc.cedula === "" ||
        EmpleadosMaysc.firtsname === "" ||
        EmpleadosMaysc.lastname === "" ||
        EmpleadosMaysc.cargo === ""
      ) {
        this.AlertaDatosFaltantes();
        return false;
      } else {
        axios
          .post(this.$conexion + "/create/employee", EmpleadosMaysc)
          .then((response) => {
            console.log(response);
            this.AlertaRegistro();

            this_.Empleados = {
              cargo: "",
              cedula: "",
              firtsname: "",
              lastname: "",
            };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    AlertaRegistro() {
      this.$swal(
        "Confirmación!",
        `Empleado ${this.Empleados.firtsname} ${this.Empleados.lastname} creado satisfactoriamente`,
        "success"
      );
    },
    AlertaDatosFaltantes() {
      this.$swal("Alerta!", `Debe diligenciar todo el fomulario`, "warning");
    },
  },
};
</script>